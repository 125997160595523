export default function getWordCount(contentState) {
  const plainText = contentState ? contentState.getPlainText('') : '';

  // CJK stands for Chinese Japanese Korean
  // Each 1000 CJK characters are usually translated into 600-700 English words
  const CJK_WORD_WEIGHT = 0.62; // Approximately

  const regexForCJK = /\p{Unified_Ideograph}/gu;
  const cjkMatches = plainText.match(regexForCJK) || []; // Find all CJK characters
  const cjkWordCount = Math.floor(cjkMatches.length * CJK_WORD_WEIGHT);

  const plainTextWithoutCJK = plainText.replace(regexForCJK, '').trim(); // Remove all CJK characters

  const regexForSpaces = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanTextWithoutCJK = plainTextWithoutCJK.replace(regexForSpaces, ' ').trim(); // replace above characters w/ space

  const latinMatches = cleanTextWithoutCJK.match(/\S+/g) || []; // Find all Latin words
  const latinWordCount = latinMatches.length;

  return cjkWordCount + latinWordCount;
}
