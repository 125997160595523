import updateStatus from './updateStatus';

export default function (response, error) {
  return (dispatch) => {
    dispatch(
      updateStatus({
        status: 'error',
        status_code: error.status,
        status_response: response,
      }),
    );
  };
}
